import React from 'react';
import styled from '@mui/system/styled';
// import BackgroundMedia from '../BackgroundMedia';
import ErrorBoundary from '@last-rev/component-library/dist/components/ErrorBoundary';
import Image from '@last-rev/component-library/dist/components/Image';
import ArtDirectedImage from '@last-rev/component-library/dist/components/ArtDirectedImage';
import sidekick from '@last-rev/component-library/dist/utils/sidekick';
import { useThemeProps } from '@mui/system';
import { MediaProps } from './Media.types';
import ParallaxItem from '../ParallaxItem';

const Media = (props: MediaProps) => {
  const { noParallax, variant, file, title, fileMobile, fileTablet, testId, sidekickLookup, ...rest } = useThemeProps({
    name: 'Media',
    props
  });
  // TODO: Add support for video
  const image = file;
  const alt = title;
  if (variant === 'embed') {
    return (
      <ErrorBoundary>
        <EmbedRoot
          {...sidekick(sidekickLookup)}
          src={image?.url}
          {...rest}
          sx={{ width: '100%', height: '100%', ...rest.sx }}
        />
      </ErrorBoundary>
    );
  }

  if (variant === 'video') {
    return (
      <ErrorBoundary>
        <VideoRoot
          {...sidekick(sidekickLookup)}
          muted
          loop
          autoPlay
          preload="auto"
          playsInline
          src={file?.url}
          {...rest}
          sx={{ width: '100%', height: '100%', ...rest.sx }}
        />
      </ErrorBoundary>
    );
  }
  if (fileTablet || fileMobile) {
    return (
      <ErrorBoundary>
        <ArtDirectedRoot
          {...sidekick(sidekickLookup)}
          title={title}
          file={file}
          fileTablet={fileTablet}
          fileMobile={fileMobile}
          {...rest}
          data-testid={testId || 'Media'}
        />
      </ErrorBoundary>
    );
  }
  if (noParallax) {
    return (
      <ErrorBoundary>
        <Root {...sidekick(sidekickLookup)} src={image?.url} alt={alt} {...rest} data-testid={testId || 'Media'} />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <ParallaxItem key={image?.url} baseOffset={300} style={{ width: '100%' }} {...rest}>
        <Root {...sidekick(sidekickLookup)} src={image?.url} alt={alt} {...rest} data-testid={testId || 'Media'} />
      </ParallaxItem>
    </ErrorBoundary>
  );
};

// Define the pieces of the Media customizable through Theme

const Root = styled(Image, {
  name: 'Media',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>``;

const ArtDirectedRoot = styled(ArtDirectedImage, {
  name: 'Media',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>``;

const EmbedRoot = styled('iframe', {
  name: 'Media',
  slot: 'EmbedRoot',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>``;

const VideoRoot = styled('video', {
  name: 'Media',
  slot: 'VideoRoot',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>`
  display: none;
`;

export default Media;
